import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { addDays, endOfDay, format, startOfDay, subDays } from "date-fns";

import { useGetWorkerAvailabilities } from "../../api/useGetWorkerAvailability";

interface Props {
  selectedDate: Date;
  timezone: string;
  isWorkerAvailabilityEnabled: boolean;
}

interface WorkerAvailabilityContent {
  title: string;
  description: string;
}

export function useGetWorkerAvailabilityBannerContent(props: Props): WorkerAvailabilityContent {
  const ldFlags = useCbhFlags();

  const availabilityConfig = ldFlags[CbhFeatureFlag.WORKER_AVAILABILITY_CALENDER_CONFIG];

  const { selectedDate, timezone, isWorkerAvailabilityEnabled } = props;
  const { data: workerAvailabilitiesInPast } = useGetWorkerAvailabilities(
    {
      startDate: format(
        startOfDay(subDays(selectedDate, availabilityConfig?.upperThresholdMarkedDays ?? 0)),
        "yyyy-MM-dd"
      ),
      endDate: format(endOfDay(selectedDate), "yyyy-MM-dd"),
      tmz: timezone,
    },
    {
      enabled: timezone.length > 0 && isWorkerAvailabilityEnabled,
    }
  );
  const { data: workerAvailabilitiesInFuture } = useGetWorkerAvailabilities(
    {
      startDate: format(startOfDay(selectedDate), "yyyy-MM-dd"),
      endDate: format(
        endOfDay(addDays(selectedDate, availabilityConfig?.lowerThresholdMarkedDays ?? 0)),
        "yyyy-MM-dd"
      ),
      tmz: timezone,
    },
    {
      enabled: timezone.length > 0 && isWorkerAvailabilityEnabled,
    }
  );
  const totalPastAvailabilities = workerAvailabilitiesInPast?.data?.availabilities
    ? Object.keys(workerAvailabilitiesInPast.data.availabilities).length
    : 0;
  const totalFutureAvailabilities = workerAvailabilitiesInFuture?.data?.availabilities
    ? Object.keys(workerAvailabilitiesInFuture.data.availabilities).length
    : 0;
  if (
    totalPastAvailabilities > 0 &&
    totalFutureAvailabilities > 0 &&
    totalPastAvailabilities >= availabilityConfig?.upperThresholdDays &&
    totalFutureAvailabilities <= availabilityConfig?.lowerThresholdDays
  ) {
    return {
      title: "Time to update your availabilities!",
      description: "Tap here to let workplaces know when you are available.",
    };
  }

  return {
    title: "Can’t find shifts fitting your schedule?",
    description: "Tap here to let workplaces know when you are available.",
  };
}
