import { type ApiResponse, put } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { WORKER_AVAILABILITIES_URL } from "./utils";

export const requestSchema = z.object({
  availableDates: z.array(z.string()),
  startDate: z.string(),
  endDate: z.string(),
  tmz: z.string(),
});

export const responseSchema = z.object({
  message: z.string(),
});

export type UpdateWorkerAvailabilitiesRequest = z.infer<typeof requestSchema>;
export type UpdateWorkerAvailabilitiesResponse = z.infer<typeof responseSchema>;

export function useUpdateWorkerAvailabilities(
  options: UseMutationOptions<
    ApiResponse<UpdateWorkerAvailabilitiesResponse>,
    AxiosError,
    UpdateWorkerAvailabilitiesRequest
  > = {}
): UseMutationResult<
  ApiResponse<UpdateWorkerAvailabilitiesResponse>,
  AxiosError,
  UpdateWorkerAvailabilitiesRequest
> {
  return useMutation({
    mutationFn: async (data) => {
      return await put({
        url: WORKER_AVAILABILITIES_URL,
        data,
        requestSchema,
        responseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PUT_AVAILABILITIES_API_FAILURE,
    },
    ...options,
  });
}
