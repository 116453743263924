import { isDefined } from "@clipboard-health/util-ts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { addMonths, endOfMonth, format, parseISO, startOfDay, startOfMonth } from "date-fns";
import { useState } from "react";

import { useAgentShifts } from "../OpenShifts/api/useAgentShifts";
import { useGetWorkerAvailabilities } from "./api/useGetWorkerAvailability";
import { type AvailabilityCalendarCellStatus } from "./types";
import { mergeSavedAvailabilitiesAndModifiedDates } from "./utils";

const VISIBLE_MONTHS = 12;

function useWorkerAvailability() {
  const currentDate = startOfDay(new Date());
  const calendarOptions = {
    minDate: startOfMonth(currentDate),
    maxDate: endOfMonth(addMonths(currentDate, VISIBLE_MONTHS)),
  };

  const [modifiedDates, setModifiedDates] = useState<
    Record<string, AvailabilityCalendarCellStatus>
  >({});

  const worker = useDefinedWorker();
  const timeZone = worker?.tmz;

  const {
    isLoading: isLoadingBookedShifts,
    isFetching: isFetchingBookedShifts,
    data: bookedShiftsData = { agentShifts: [] },
  } = useAgentShifts({
    startDate: calendarOptions.minDate,
    endDate: calendarOptions.maxDate,
    groupByDate: false,
    tmz: timeZone,
  });

  const {
    isLoading: isLoadingAvailabilities,
    isFetching: isFetchingAvailabilities,
    data: savedAvailabilityData,
    refetch: refetchAvailableDates,
  } = useGetWorkerAvailabilities({
    startDate: format(calendarOptions.minDate, "yyyy-MM-dd"),
    endDate: format(calendarOptions.maxDate, "yyyy-MM-dd"),
    tmz: timeZone,
  });

  const savedAvailableDates = Object.keys(savedAvailabilityData?.data.availabilities ?? {});

  const currentDates: Record<string, AvailabilityCalendarCellStatus> =
    mergeSavedAvailabilitiesAndModifiedDates(savedAvailableDates, modifiedDates);

  const alreadyBookedDates = bookedShiftsData.agentShifts
    .map(({ start }) => start)
    .filter((start) => isDefined(start))
    .map((start) => startOfDay(parseISO(start)));

  return {
    alreadyBookedDates,
    modifiedDates,
    setModifiedDates,
    savedAvailableDates,
    currentDates,
    timeZone,
    calendarOptions,
    refetchAvailableDates,
    isLoading:
      isLoadingAvailabilities ||
      isLoadingBookedShifts ||
      isFetchingAvailabilities ||
      isFetchingBookedShifts,
  };
}

export const [WorkerAvailabilityProvider, useWorkerAvailabilityContext] =
  constate(useWorkerAvailability);
