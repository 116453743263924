import { post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { AttendanceConfirmationChannel, type ConfirmAttendanceResponseCode } from "../constant";

const confirmAttendanceResponseSchema = z.object({ attendanceConfirm: z.boolean() });

export type ConfirmAttendanceResponse = z.infer<typeof confirmAttendanceResponseSchema>;

const confirmAttendanceRequestSchema = z.object({
  shiftId: z.string(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  eta: z.string().optional(),
  channel: z
    .enum([
      AttendanceConfirmationChannel.MY_SHIFT_PAGE,
      AttendanceConfirmationChannel.FACILITY_CHAT,
    ])
    .optional(),
  sendbirdMessageId: z.number().optional(),
  workplaceId: z.string().optional(),
});

export type ConfirmAttendancePayload = z.infer<typeof confirmAttendanceRequestSchema>;

export interface ConfirmAttendanceErrorResponse {
  type: ConfirmAttendanceResponseCode;
  status: number;
  message: string;
}

export function useConfirmAttendance(urgentShiftsServiceUrl: string) {
  return useMutation({
    mutationFn: async (data: ConfirmAttendancePayload) => {
      const response = await post({
        url: `${urgentShiftsServiceUrl}/attendance/confirm`,
        data,
        requestSchema: confirmAttendanceRequestSchema,
        responseSchema: confirmAttendanceResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_CONFIRM_ATTENDANCE_FAILURE,
    },
  });
}
