import { useMonthRange } from "@src/appV2/lib/Calendar/useMonthRange";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { format, isBefore, startOfDay } from "date-fns";
import { type ReactElement } from "react";

import { AvailabilityCalendar } from "../../../lib/AvailabilityCalendar";
import { useWorkerAvailabilityContext } from "../../context";
import { AvailabilityCalendarCellStatus } from "../../types";
import { getAvailableDatesFromCurrentDates } from "../../utils";

export interface WorkerAvailabilityFormProps {
  isSubmitting: boolean;
}

export function WorkerAvailabilityForm(props: WorkerAvailabilityFormProps): ReactElement {
  const { isSubmitting } = props;

  const { showInfoToast } = useToast();
  const {
    setModifiedDates,
    currentDates,
    modifiedDates,
    alreadyBookedDates,
    isLoading,
    calendarOptions,
  } = useWorkerAvailabilityContext();

  const currentDate = new Date();

  const { dateRange, onPrevious, onNext } = useMonthRange({
    startDate: currentDate,
  });

  return (
    <AvailabilityCalendar
      isLoading={isSubmitting || isLoading}
      dateRange={dateRange}
      options={{
        ...calendarOptions,
        headerTitle: format(dateRange.startDate, "MMMM yyyy"),
      }}
      availableDates={getAvailableDatesFromCurrentDates(currentDates)}
      blockedDates={alreadyBookedDates}
      onDateSelect={(params) => {
        const { id, value, isSelected, isDisabled } = params;
        const isPastDate = isBefore(value, startOfDay(new Date()));

        if (isPastDate) {
          return;
        }

        if (isDisabled) {
          showInfoToast(`You are working on ${format(value, "PPP")}.`);
          return;
        }

        setModifiedDates({
          ...modifiedDates,
          [id]: isSelected
            ? AvailabilityCalendarCellStatus.UNAVAILABLE
            : AvailabilityCalendarCellStatus.AVAILABLE,
        });
      }}
      onPrevious={onPrevious}
      onNext={onNext}
    />
  );
}
