import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { HyperTrack } from "hypertrack-sdk-ionic-capacitor";
import { useCallback, useEffect, useRef } from "react";

export enum HyperTrackSdkState {
  NOT_INITIALIZED = "NOT_INITIALIZED",
  INITIALIZED = "INITIALIZED",
  INITIALIZATION_FAILED = "INITIALIZATION_FAILED",
  RESET_FAILED = "RESET_FAILED",
  INITIALIZING = "INITIALIZING",
  RESETTING = "RESETTING",
}

/**
 * Associates the device with the user by setting the 'name' of the device and the 'driver_handle' to the workers userId
 * @param userId - the userId of the worker
 */
async function initializeHyperTrack(userId: string): Promise<[void, void]> {
  return await Promise.all([
    HyperTrack.setName(userId),
    HyperTrack.setMetadata({ driver_handle: userId }),
  ]);
}

/**
 * Removes the association of the device with the user by resetting the 'name' of the device and the 'driver_handle' to empty strings
 */
async function resetHyperTrack(): Promise<[void, void]> {
  return await Promise.all([HyperTrack.setName(""), HyperTrack.setMetadata({ driver_handle: "" })]);
}

/**
 * Initializes the HyperTrack SDK based on the user's feature flag and userId
 *
 * HyperTrack is an alternative to Radar for tracking the user's location
 * We will be trialing it during Q1'24 after which you should expect this function
 * to be simplified and this comment to be updated.
 * If it's after Q1'24 and you're still seeing this comment, please reach out to the
 * Core Services team and remind us to clean this up.
 */
export function useHyperTrack(userId: string | undefined): void {
  const isHyperTrackPilotUser = useCbhFlag(CbhFeatureFlag.HYPER_TRACK_TRIAL, {
    defaultValue: false,
  });
  const hyperTrackSdkStateRef = useRef(HyperTrackSdkState.NOT_INITIALIZED);
  const isValidUser = isDefined(userId) && userId !== "" && isHyperTrackPilotUser;

  const updateHyperTrackLifecycle = useCallback(async () => {
    if (isValidUser && hyperTrackSdkStateRef.current === HyperTrackSdkState.NOT_INITIALIZED) {
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_TRIAL_ENABLED);
      try {
        hyperTrackSdkStateRef.current = HyperTrackSdkState.INITIALIZING;
        await initializeHyperTrack(userId ?? "");
        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_INIT_SUCCESS);
        hyperTrackSdkStateRef.current = HyperTrackSdkState.INITIALIZED;
      } catch (error) {
        logError(APP_V2_APP_EVENTS.HYPER_TRACK_INIT_FAILURE, {
          error,
          metadata: {
            userId,
          },
        });
        hyperTrackSdkStateRef.current = HyperTrackSdkState.INITIALIZATION_FAILED;
      }
    } else if (!isValidUser && hyperTrackSdkStateRef.current === HyperTrackSdkState.INITIALIZED) {
      try {
        // 'turn off' hypertrack if the user is not a hypertrack pilot user or if the userId is not defined (i.e., logged out).
        hyperTrackSdkStateRef.current = HyperTrackSdkState.RESETTING;
        await resetHyperTrack();
        logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_RESET_SUCCESS);
        hyperTrackSdkStateRef.current = HyperTrackSdkState.NOT_INITIALIZED;
      } catch (error) {
        logError(APP_V2_APP_EVENTS.HYPER_TRACK_RESET_FAILURE, {
          error,
        });
        hyperTrackSdkStateRef.current = HyperTrackSdkState.RESET_FAILED;
      }
    }
  }, [isValidUser, userId]);

  useEffect(() => {
    if (isCapacitorPlatform()) {
      void updateHyperTrackLifecycle();
    }
  }, [updateHyperTrackLifecycle]);
}
