import { RootPaths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { BannerLink } from "@src/appV2/lib/Alert";
import { type Worker } from "@src/appV2/Worker/api/types";
import { intersection } from "lodash";

import { WORKER_AVAILABILITIES_PATH } from "../../paths";
import { useGetWorkerAvailabilityBannerContent } from "./useGetWorkerAvailabilityBannerContent";

interface WorkerAvailabilityBannersProps {
  worker: Worker;
}

export function WorkerAvailabilityBanners(props: WorkerAvailabilityBannersProps) {
  const { worker } = props;

  const agentAvailableQualifications =
    worker?.licensesData?.map((item) => item.qualification) ?? [];
  const { qualifications } = useCbhFlag(CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY, {
    defaultValue: { qualifications: [] },
  });
  const isWorkerAvailabilityEnabled =
    intersection(qualifications, agentAvailableQualifications).length > 0;

  const bannerContent = useGetWorkerAvailabilityBannerContent({
    selectedDate: new Date(),
    timezone: worker.tmz,
    isWorkerAvailabilityEnabled,
  });

  if (!isWorkerAvailabilityEnabled) {
    return null;
  }

  return (
    <BannerLink
      title={bannerContent.title}
      to={`${RootPaths.APP_V2_HOME}/${WORKER_AVAILABILITIES_PATH}`}
      severity="info"
    >
      {bannerContent.description}
    </BannerLink>
  );
}
