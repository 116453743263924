import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const checkAttendanceV1Schema = z.object({ attendanceCheck: z.boolean() });

export type CheckAttendanceV1Response = z.infer<typeof checkAttendanceV1Schema>;

export interface CheckAttendanceV1Props {
  urgentShiftsServiceUrl: string;
  shiftStart: string;
  shiftId: string;
  options?: UseGetQueryOptions<CheckAttendanceV1Response>;
}

export function useCheckAttendanceV1(
  params: CheckAttendanceV1Props
): UseQueryResult<CheckAttendanceV1Response> {
  const { shiftId, shiftStart, urgentShiftsServiceUrl, options = {} } = params;
  return useGetQuery({
    url: `${urgentShiftsServiceUrl}/v1/attendance/check`,
    responseSchema: checkAttendanceV1Schema,
    queryParams: { shiftId, shiftStart },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_CHECK_ATTENDANCE_V1_FAILURE,
    },
    enabled: isDefined(shiftId) && isDefined(shiftStart) && isDefined(urgentShiftsServiceUrl),
    ...options,
  });
}
