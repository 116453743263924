import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { WORKER_AVAILABILITIES_URL } from "./utils";

export const requestSchema = z.object({
  startDate: z.string(),
  endDate: z.string(),
  tmz: z.string(),
});

export const responseSchema = z.object({
  data: z.object({
    availabilities: z.record(z.string(), z.string()),
  }),
});

export type GetWorkerAvailabilitiesRequest = z.infer<typeof requestSchema>;
export type GetWorkerAvailabilitiesResponse = z.infer<typeof responseSchema>;

export interface GetWorkerAvailabilitiesParams {
  startDate: string;
  endDate: string;
  tmz: string;
}

export function useGetWorkerAvailabilities(
  queryParams: GetWorkerAvailabilitiesParams,
  options: UseGetQueryOptions<GetWorkerAvailabilitiesResponse> = {}
): UseQueryResult<GetWorkerAvailabilitiesResponse> {
  return useGetQuery({
    url: WORKER_AVAILABILITIES_URL,
    queryParams,
    requestSchema,
    responseSchema,
    meta: {
      userErrorMessage: "Something went wrong while fetching your availabilities",
      logErrorMessage: APP_V2_APP_EVENTS.GET_AVAILABILITIES_API_FAILURE,
    },
    ...options,
  });
}
